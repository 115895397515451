import $ from 'jquery';

function showFullNav() {
    $('.main-nav ul').children('li').addClass('active');
    setTimeout(function() {
        $('.main-nav ul').children('li').removeClass('active');
    },2000);
}

function popNav() {
  var window_top = $(window).scrollTop();
  $('.navPoint').each(function() {
    var nav = $(this).data('nav');
    var top = $(this).offset().top;
    if (window_top > top && window_top < top + 600) {
        $(nav).addClass('active');
    } else {
  			$(nav).removeClass('active');
		}
  });
}

$(function() {
    $(window).scroll(popNav);
    popNav();
    showFullNav();
});

function showLogo() {
    var window_top = $(window).scrollTop();
    var top = $('#section-marker-hooray-science').offset().top;
		// window.addEventListener("resize", function() {
	    if (window.matchMedia("(min-width: 768px)").matches) {
				if (window_top >= top) {
						$('#nav-to-top').addClass('visible');
				} else {
						$('#nav-to-top').removeClass('visible');
				}
	    } else {
	        $('#nav-to-top').addClass('visible');
	    }
		// });

}
$(function() {
    $(window).scroll(showLogo);
    showLogo();
});
