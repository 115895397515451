import $ from 'jquery';
//
// import "scrollmagic";
//
window.ScrollMagic = require("scrollmagic");

var controller = new ScrollMagic.Controller();


$(function () { // wait for document ready
	// init
	var controller = new ScrollMagic.Controller({
		globalSceneOptions: {
			triggerHook: 'onLeave'
		}
	});

	if (window.matchMedia("(min-width: 1025px)").matches) {
		var container_scene = new ScrollMagic.Scene({triggerElement: "#body-load", duration: "400%"})
			.setPin(".main-header")
			// .addIndicators({name: "pin container"}) // add indicators (requires plugin)
			.addTo(controller);
	} else {
		var container_scene = new ScrollMagic.Scene({triggerElement: "#body-load", duration: "200%"})
			.setPin(".main-header")
			// .addIndicators({name: "pin container"}) // add indicators (requires plugin)
			.addTo(controller);
	}

	var items_tween = new TimelineMax ()
		.add([
			TweenMax.fromTo("#parallaxContainer .item.cardinal", 12, {top: "60%", right: "10%"}, {top: "18%", right: "28%", ease: Power3.easeOut}),
			TweenMax.fromTo("#parallaxContainer .item.flag", 10, {top: "60%", right:"30%", width: "1%"}, {top: "23%",right:"4%", width: "24%", ease: Power3.easeOut}),
			TweenMax.fromTo("#parallaxContainer .item.flag img", 11, {rotation: -70}, {rotation: 0, ease: Power3.easeOut}),
			TweenMax.fromTo("#parallaxContainer .item.sensor", 2, {top: "80%", right: "40%", width: "20%"}, {top: "18%", right: "45%", width: "39.8%", ease: Power3.easeOut}),
			TweenMax.fromTo("#parallaxContainer .item.corn.one", 3, {top: "90%"}, {top: "64%", ease: Power3.easeOut}),
			TweenMax.fromTo("#parallaxContainer .item.corn.two", 4, {top: "90%"}, {top: "57%", ease: Power3.easeOut}),
			TweenMax.fromTo("#parallaxContainer .item.corn.three", 5, {top: "90%"}, {top: "63%", ease: Power3.easeOut}),
			TweenMax.fromTo("#parallaxContainer .item.corn.four", 6, {top: "90%"}, {top: "69%", ease: Power3.easeOut}),
			TweenMax.fromTo("#parallaxContainer .item.corn.five", 7, {top: "90%"}, {top: "69%", ease: Power3.easeOut}),
			TweenMax.fromTo("#parallaxContainer .item.light", 8, {top: "90%", right:"0", width: "27%"}, {top: "44.5%", right:"19%", width: "44.8%", ease: Power3.easeOut}),
			TweenMax.fromTo("#parallaxContainer .item.light img", 9, {rotation: -10}, {rotation: 0, ease: Power3.easeOut}),
			TweenMax.fromTo("#parallaxContainer .item.greenhouse", 1, {top: "80%", right:"69%"}, {top: "59.5%",right:"59.5%", ease: Power3.easeOut})
		]);

		if (window.matchMedia("(min-width: 1025px)").matches) {
			var items_scene = new ScrollMagic.Scene({triggerElement: "#body-load", duration: "400%"})
				.setTween(items_tween)
				// .addIndicators({name: "items"}) // add indicators (requires plugin)
				.addTo(controller);
		} else {
			var items_scene = new ScrollMagic.Scene({triggerElement: "#body-load", duration: "200%"})
				.setTween(items_tween)
				// .addIndicators({name: "items"}) // add indicators (requires plugin)
				.addTo(controller);
		}

});
