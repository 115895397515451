import $ from 'jquery';

$('.accordion .heading').click(function(){
    var item = $(this).parents('li');

    if(item.hasClass('active')){
        item.removeClass('active');
    }else{
        item.addClass('active');
    }
});