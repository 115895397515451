import $ from 'jquery';

window.bodyScrollLock = require("body-scroll-lock");

const targetElement = document.querySelector(".modal");

// import { disableBodyScroll, clearAllBodyScrollLocks } from './body-scroll-lock';
//const bodyScrollLock = require('./bodyScrollLock');

$('.open-modal').each(function() {
    const targetElement = document.querySelector('.modal.active');
    var modalClass = $(this).data('modal');
    $(this).on('click', function() {
        $('.modal.' + modalClass).addClass('active');
				$('body').addClass('scroll-locked');
        $('.overlay').addClass('display');
        // disableBodyScroll(targetElement);
				bodyScrollLock.disableBodyScroll(targetElement);
        console.log('scroll disabled');
    });
});

$('.modal, .overlay').on('click', function() {
    $('.modal').removeClass('active');
		$('body').removeClass('scroll-locked');
    $('.overlay').removeClass('display');
    // clearAllBodyScrollLocks();
		bodyScrollLock.enableBodyScroll(targetElement);
    console.log('scroll enabled');
		// bodyScrollLock.clearAllBodyScrollLocks();
});

// $('.modal-image').on('click', function() {
//     var image = $(this).children('img');
//         $('.popup-overlay').addClass('display');
//         image.clone().prependTo(".screen-comp-modals").addClass('modal-popup');
// });
